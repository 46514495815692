<template>
	<div class="home">
		<div class="accueil_mobile_searchbar_component">
			<div class="navbarr">
				<div class="search-bar" @click="openActionSheet()">
					<i class="icon">&#x1F50D;</i>
					<input
					id="customInput"
					type="text"
					placeholder="Destination"
					:value="valeurRecherche"
					disabled
					/>
				</div>
			</div>
	  	</div>
  
	  	<transition name="slide-up">
			<div class="actionsheet" v-if="isOpen">
		  		<div class="actionsheet-overlay" @click="closeActionSheet()"></div>
  
		  			<div class="actionsheet-content">
						<div class="box">
							<div class="page-header">
								<p class="title-detail">Recherche</p>
								<button class="delete" aria-label="close" @click="closeActionSheet()"></button>
							</div>
  
							<div class="field">
								<div class="control" style="margin: 0px -12px;">
								<div class="select is-fullwidth">
									<select v-model="rechercheInfos.destination" placeholder="Abidjan">
										<option value="Abidjan">Abidjan</option>
									<option v-for="item in listZone" :key="item.libelle" :value="item.libelle">{{item.libelle}}</option>
									</select>
								</div>
								</div>
							</div>
  
							<div class="field">
								<div class="columns is-mobile">
									<div class="column" style="padding: 0px">
										<div class="field">
										<div class="control">
											<input
											v-model="rechercheInfos.du"
											type="date"
											class="input"
											placeholder="Date de début"
											/>
										</div>
										</div>
									</div>
									<div class="column" style="padding: 0px">
										<div class="field">
											<div class="control">
												<input
												v-model="rechercheInfos.au"
												type="date"
												class="input"
												placeholder="Date de fin"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
  
							<div class="field">
								<div class="columns is-mobile" style="margin: 0px -12px">
									<div class="column is-half" style="padding: 0px">
									<el-popover placement="bottom" :width="175" trigger="click">
										<template #reference>
										<el-input
											v-model="rechercheInfos.adulte"
											style="max-width: 600px"
											size="large"
											placeholder="Saisir le nombre d'adultes"
										>
											<template #append>Adulte</template>
										</el-input>
										</template>
										<el-input-number v-model="rechercheInfos.adulte" :min="1" :max="10" @change="handleChange" />
									</el-popover>
									</div>
									<div class="column is-half" style="padding: 0px;">
									<el-popover placement="bottom" :width="175" trigger="click">
										<template #reference>
										<el-input
											v-model="rechercheInfos.enfant"
											style="max-width: 600px"
											size="large"
											placeholder="Saisir le nombre d'enfants"
										>
											<template #append>Enfant</template>
										</el-input>
										</template>
										<el-input-number v-model="rechercheInfos.enfant" :min="1" :max="10" @change="handleChange" />
									</el-popover>
									</div>
								</div>
							</div>
  
							<div class="field">
								<div class="control" style="margin: 0px -12px;">
								<button style="width: 100%" class="button is-primary" @click="closeActionSheet2">Rechercher</button>
								</div>
							</div>
						</div>
		  			</div>
			</div>
	  	</transition>
	</div>
  </template>
  
  <script>
  export default {
	name: 'HomeView',
	computed: {
		rechercheInfos() {
			return this.$store.state.rechercheInfos;
		},
	},
	data() {
	  return {
		isOpen: false,
		selectedDate: [null, null],
		destination: 'Abidjan',
		adulte: 1,
		enfant: 1,
		valeurRecherche: '',
		selectedOption: '',
		dateDebut: '',
		dateFin: '',
		listZone: [
			{libelle: 'Abidjan Cocody'},
			{libelle: 'Abidjan Abobo'},
			{libelle: 'Abidjan Treichville'},
			{libelle: 'Abidjan Portbouet'},
			{libelle: 'Abidjan Yopougon'},
		]
	  };
	},
	methods: {
	  searchData() {
		const info = {
			destination: this.rechercheInfos.destination,
			du: this.rechercheInfos.du,
			au: this.rechercheInfos.au,
			adulte: this.rechercheInfos.adulte,
			enfant: this.rechercheInfos.enfant,
		}
		this.$store.commit('setRechercheInfos', info);
	  },
	  openActionSheet() {
		this.isOpen = true;
		document.body.style.overflow = 'hidden';
	  },
	  closeActionSheet() {
		this.isOpen = false;
		document.body.style.overflow = '';
	  },
	  closeActionSheet2() {
		const startDate = new Date(this.dateDebut);
		const endDate = new Date(this.dateFin);
  
		if (startDate > endDate) {
		  alert("La date de fin doit être ultérieure à la date de début.");
		  return;
		}
  
		this.valeurRecherche = `${this.rechercheInfos.destination}, ${startDate.toLocaleDateString()} à ${endDate.toLocaleDateString()}, ${this.adulte} adultes, Option: ${this.selectedOption}`;
		this.closeActionSheet();
	  }
	},
	mounted() {
	  // Set dateDebut to tomorrow's date
	  const tomorrow = new Date();
	  tomorrow.setDate(tomorrow.getDate() + 1);
	  this.rechercheInfos.du = tomorrow.toISOString().substr(0, 10);
  
	  // Set dateFin to the day after tomorrow
	  const dayAfterTomorrow = new Date();
	  dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
	  this.rechercheInfos.au = dayAfterTomorrow.toISOString().substr(0, 10);
	}
  };
  </script>
  
  <style scoped>
  @import url('https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css');
  
  .home {
	padding: 20px;
  }
  
  .search-bar {
	display: flex;
	align-items: center;
	background-color: #f0f0f0;
	border-radius: 20px;
	padding: 1px 15px;
  }
  
  .search-bar input {
	border: none;
	outline: none;
	background: none;
	flex: 1;
	padding: 12px;
	font-size: 16px;
	pointer-events: none;
  }
  
  .search-bar .icon {
	margin-right: 10px;
	color: #888;
  }
  
  .actionsheet {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.5);
	z-index: 1500;
  }
  
  .actionsheet-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #00000024;
  }
  
  .actionsheet-content {
	position: relative;
	background: #fff;
	border-radius: 10px;
	width: 90%;
	max-width: 600px;
	z-index: 1510;
  }
  
  .page-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
  }
  </style>
  