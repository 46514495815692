// src/main.js

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bulma/css/bulma.css';
import '@fortawesome/fontawesome-free/css/all.css';
import axios from '@/axios.js'; 
import './assets/style.css';
import './assets/fonts/NeueMachina-Light.otf'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import VueMobileDetection from "vue-mobile-detection";// Importez axios explicitement
import DatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
// Vuesax
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';

// Import des styles globaux (si nécessaire)
import './registerServiceWorker'; // Assurez-vous que ce fichier contient ce qui est nécessaire pour le service worker

// Création de l'application Vue
const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
// Utilisation des plugins (router et store)
app.use(router);
app.use(store);
app.use(ElementPlus);
app.use(VueMobileDetection);
app.use('DatePicker', DatePicker);

// Ajouter axios aux propriétés globales
app.config.globalProperties.$axios = axios;

// Montage de l'application dans l'élément avec l'ID 'app'
app.mount('#app');
